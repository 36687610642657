import './App.css';
import Button from '@material-ui/core/Button';

function Nav() {
  return (
    <div className="md-toolbar md-theme-demo-light md-elevation-0">
      <div id="title" className="title">
        Blockpire
      </div>
      <div id="subLinks" style={{ paddingLeft: '84%', marginTop: '-20px', paddingBottom: '20px', fontFamily: 'Karla-Regular', fontSize: '16px'}}>
        <Button className="md-button-clean" style={{marginRight: '20px'}}>
          <div className="md-button-content">Product</div>
        </Button>
        <Button className="md-button-clean">
          <div className="md-button-content">Company</div>
        </Button>
      </div>
    </div>
  );
}

export default Nav;
