import './App.css';
import Nav from './Nav.js';
import Product from './images/product.png';
import ReactGA from 'react-ga';

ReactGA.initialize('G-H6W279PMW4');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="defaultLayout">
      <Nav />
      <div className="md-layout">
        <div className="md-layout-item hero-intro">
          BUILD YOUR TRIBE<br />
          <div className="divider">
            &nbsp;
          </div>
          <div className="subText">
            Your style. Your people. Your vibe.
          </div>
          <img src={Product} width="888" height="413" alt="Blockpire dApp" />
        </div>
      </div>
    </div>
  );
}

export default App;
